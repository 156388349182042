<template>
    <div>
        <v-container grid-list-lg class="mt-7">
            <v-layout row wrap>
                <v-flex xs12 sm6 class="d-flex justify-center">
                    <v-skeleton-loader type="image" v-if="loadingCategories"></v-skeleton-loader>
                    <v-avatar
                            class="ma-3"
                            :style="{border: '5px solid ' + primary}"
                            size="300"
                            v-else
                    >
                        <v-img :src="getProductImage(currentProduct.imageUrl)"></v-img>
                    </v-avatar>
                </v-flex>
                <v-flex xs12 sm6>
                    <product-detail />
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import ProductDetail from '../components/Product/ProductDetail'
    import {getProductImage} from '../Helper'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: 'ProductPage',
        metaInfo () {
            return {
                title: this.currentProduct ? this.currentProduct.name : '',
            }
        },
      data() {
          return {
            primary: window.primary,
          }
      },
        mixins: [getProductImage],
        components: {
            ProductDetail
        },
        computed: {
            ...mapGetters([
                'allProducts',
                'currentProduct',
                'loadingCategories'
            ]),
            imageData() {
                if (this.currentProduct) {
                    return this.currentProduct.imageData;
                }

                return null;
            }
        },
        mounted() {
            let productAlias = this.$router.currentRoute.params;
            if (typeof productAlias.alias !== 'undefined') {
                this.changeProduct();
            }
        },
        methods: {
            ...mapActions([
                'changeProduct',
            ]),
        }
    }
</script>

