<template>
    <div class="px-sm-0 px-2">
        <v-btn block class="black--text" :loading="loading" :disabled="loading || (!form.paymentMethodId && !form.shippingMethodId) || !valid" color="primary" depressed tile large @click="submit($event)">Závazně objednat a zaplatit</v-btn>
        <p class="caption mt-2">Dokončením objednávky souhlasíte s uzavřením darovací smlouvy ve znění uvedeného v <a
                target="_blank"
                href="/pdf/Obchodni_podminky_portalu_Stravenka.pdf"
                @click.stop
        >Obchodních podmínkách</a>.</p>
        <v-checkbox v-model="form.marketingAgreement" :rules="[rules.required]">
            <template v-slot:label>
                <div>
                    Souhlasím se zpracováním osobních údajů. Naše pravidla Ochrany osobních údajů najdete
                    <template>
                        <a
                                target="_blank"
                                href="/pdf/Stravenka_GDPR_final.pdf"
                                @click.stop
                        >zde</a>
                    </template>
                </div>
            </template>
        </v-checkbox>
    </div>
</template>

<script>
    export default {
        name: 'PlaceOrder',
        props: ['form', 'loading', 'valid'],
        data () {
            return {
                rules: window.validationRules.getRules()
            }
        },
        methods: {
            submit(event) {
                this.$emit('submit', event);
            }
        }
    }
</script>
