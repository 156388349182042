<template>
    <v-card flat>
        <v-card-title class="pb-0">
            <h3 class="checkout-section-title">Platební metody</h3>
        </v-card-title>
        <v-card-text>
            <v-radio-group v-model="form.paymentMethodId" v-if="paymentMethods.length" :rules="[rules.required]">
                <v-radio
                        v-for="n in paymentMethods"
                        :key="n.id"
                        :label="n.name"
                        :value="n.id"
                >
                    <template v-slot:label>
                        <v-list three-line class="pa-0">
                            <v-list-item>
                                <img class="payment-icon mr-4" :src="getPaymentIcon(n.iconName)" v-if="$vuetify.breakpoint.mdAndUp" />
                                <v-list-item-content>
                                    <v-list-item-title class="title">
                                        {{ n.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ n.note }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </template>
                </v-radio>
            </v-radio-group>
            <div v-if="!paymentMethods.length">
                Bohužel nejsou k dispozici žádné platební metody.
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    export default {
        name: 'PaymentMethods',
        props: ['form'],
        data () {
            return {
                loadingMethods: false,
                rules: window.validationRules.getRules()
            }
        },
        mounted() {
            if (!this.paymentMethods.length) {
                this.retrievePaymentMethods();
            }
        },
        computed: {
            ...mapGetters([
                'paymentMethods',
                'paymentMethodsLoaded'
            ])
        },
        methods: {
            ...mapActions([
                'retrievePaymentMethods'
            ]),
            getPaymentIcon(icon) {
                return require('../../assets/payment_methods/' + icon)
            }
        }
    }
</script>

<style lang="scss">
    .payment-icon {
        max-width: 100px;
        margin-right: 10px;
    }
</style>
