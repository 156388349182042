<template>
    <v-snackbar
            v-model="snackbar.active"
            :color="snackbar.type"
            :timeout="-1"
            :top="$vuetify.breakpoint.xsOnly"
    >
        {{ snackbar.message }}
        <v-btn
                dark
                color="black"
                @click="goToCheckout"
        >
            K pokladně
        </v-btn>
    </v-snackbar>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'Snackbar',
        computed: {
            ...mapGetters([
                'snackbar'
            ])
        },
        methods: {
            ...mapActions([
                'hideSnackbar'
            ]),
            goToCheckout() {
                this.hideSnackbar();
                this.$router.push('/checkout')
            }
        }
    }
</script>
