import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
let primary = '';
let secondary = '';
let logo = '';
let cart = '';

switch (new Date().getMonth()) {
    case 11:
        primary = '#eb4a00';
        secondary = '#fab994';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2023-12.png');
        cart = require('../assets/cart/Button-kosik-2021-12.png')
        break;
    case 10:
        primary = '#8e78b3';
        secondary = '#c6c0de';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-11.png');
        cart = require('../assets/cart/Button-kosik-2021-11.png')
        break;
    case 9:
        primary = '#c9583a';
        secondary = '#e9b5a1';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-10.png');
        cart = require('../assets/cart/Button-kosik-2021-10.png')
        break;
    case 8:
        primary = '#f48e00';
        secondary = '#fcd09e';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-09.png');
        cart = require('../assets/cart/Button-kosik-2021-09.png')
        break;
    case 7:
        primary = '#a4a171';
        secondary = '#d5d2c0';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-08.png');
        cart = require('../assets/cart/Button-kosik-2021-08.png')
        break;
    case 6:
        primary = '#6cbd27';
        secondary = '#c5e0ae';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-07.png');
        cart = require('../assets/cart/Button-kosik-2021-07.png')
        break;
    case 5:
        primary = '#fec800';
        secondary = '#fde7a7';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-06.png');
        cart = require('../assets/cart/Button-kosik-2021-06.png')
        break;
    case 4:
        primary = '#f29ac0';
        secondary = '#fad5e5';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-05.png');
        cart = require('../assets/cart/Button-kosik-2021-05.png')
        break;
    case 3:
        primary = '#5ebfb9';
        secondary = '#bfe4e1';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-04.png');
        cart = require('../assets/cart/Button-kosik-2021-04.png')
        break;
    case 2:
        primary = '#bfd100';
        secondary = '#e3ecae';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-03.png');
        cart = require('../assets/cart/Button-kosik-2021-03.png')
        break;
    case 1:
        primary = '#8e7c75';
        secondary = '#cac2bf';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-02.png');
        cart = require('../assets/cart/Button-kosik-2021-02.png')
        break;
    case 0:
    default:
        primary = '#00b0e9';
        secondary = '#a4def8';
        logo = require('../assets/logo/2020-147_Stravenky-logoA-2024-01.png');
        cart = require('../assets/cart/Button-kosik-2021-01.png')
        break;
}

window.primary = primary;
window.secondary = secondary;
window.logo = logo;
window.cart = cart;

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: primary,
                secondary: secondary,
                info: secondary,
                success: '#8BC34A',
                error: '#e80028'
            },
            dark: {
                primary: primary,
                secondary: '#82daf0',
                info: 'rgba(0,176,233,0.4)',
                success: '#8BC34A',
                error: '#e80028'
            }
        }
    }
});
