<template>
    <div>
        <v-overlay :value="checkingCustomer">
            <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'ScanProduct',
        mounted() {
            let productId = this.$router.currentRoute.query.productId;
            if (typeof productId === 'undefined' || !productId.length) {
                this.$router.push('/');
                return;
            }

            this.scanProduct({product: productId});
        },
        computed: {
            ...mapGetters([
                'checkingCustomer'
            ])
        },
        methods: {
            ...mapActions([
                'scanProduct'
            ])
        }
    }
</script>
