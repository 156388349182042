<template>
  <v-container grid-list-lg>
    <v-row class="text-sm-left text-center my-3">
      <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
        <div class="font-weight-bold ml-2 headline">STRAVENKY PRO ZVÍŘATA</div>
      </v-col>
      <v-col cols="12" v-if="!loadingCategories">
        Zakoupením Stravenky se zapojíte do projektu mikrosponzoringu Zoo Praha.
        Stravenka je určena jako příspěvek na krmení pro jeden z {{allProducts.length}} vybraných
        zvířecích druhů. Její cena symbolizuje finanční náročnost té které krmné
        denní či měsíční dávky, nepředstavuje však přesné náklady. S vaší pomocí
        zajistíme zvířatům pestrý jídelníček i v této složité době. Lze
        přispívat každý měsíc, stravenky budou odlišeny barevně. Jako poděkování
        obdržíte certifikát či úvodní banner na váš Facebook, kterým můžete svou
        pomoc i projekt propagovat. Kompletní informace o projektu naleznete
        <a href="https://zoopraha.cz/stravenky" target="_blank">zde</a>.
      </v-col>
    </v-row>
    <v-layout row wrap v-if="loadingCategories">
      <v-flex xs12 sm6 v-for="n in 2" :key="n">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-else>
      <v-flex xs12 sm6 mb-2>
        <v-text-field
            label="VYHLEDAT ZVÍŘE ..."
            clearable
            solo
            flat
            :background-color="secondary"
            hide-details
            height="36"
            v-model="search"
            class="search"
        >
          <template v-slot:prepend>
            <v-avatar color="primary" rounded size="36">
              <v-icon color="black">mdi-magnify</v-icon>
            </v-avatar>
          </template>
        </v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex
          xs12
          sm6
          md6
          v-for="product in filteredProducts"
          :key="product.id"
      >
        <v-card class="flex-column fill-height">
          <div class="d-flex flex-no-wrap justify-space-between fill-height">
            <div class="fill-height">
              <div class="d-flex justify-space-between flex-column fill-height">
                <div>
                  <v-card-title
                      class="headline pb-0"
                      v-text="product.name"
                  ></v-card-title>
                  <v-card-text
                      class="pt-0"
                      v-html="product.shortDescription"
                  ></v-card-text>
                </div>
                <v-card-actions class="d-flex">
                  <v-btn
                      dark
                      class="ml-2"
                      color="primary"
                      depressed
                      @click="openDialog(product)"
                  >Koupit stravenky</v-btn
                  >
                </v-card-actions>
              </div>
            </div>
            <router-link :to="'/product/' + product.alias" style="position: relative;">
              <v-avatar class="ma-3" :style="{border: '5px solid ' + primary}" size="125">
                <v-img :src="getProductImage(product.imageUrl)"></v-img>
              </v-avatar>
              <span style="position: absolute; right: 10px; bottom: 10px" v-if="checkNewProduct(product)">
                <v-img :src="require('@/assets/new-icon.png')" max-width="50" />
              </span>
            </router-link>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="590"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title class="headline"> Koupit stravenku </v-card-title>
        <v-card-text v-html="currentProduct.description"></v-card-text>
        <v-card-text>
          <v-layout column class="text-center">
            <div class="subtitle-1">Množství stravenek</div>
            <vue-numeric-input
                v-model="qty"
                :min="1"
                :max="10"
                align="center"
                size="100px"
                class="mx-auto"
            ></vue-numeric-input>
          </v-layout>
          <v-layout column class="text-center mt-2">
            <div class="subtitle-1">
              Celková cena:
              <strong>{{ qty * currentProduct.amountWithVat }}</strong> Kč
            </div>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" depressed @click="dialogClose"> Zavřít </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" depressed @click="dialogAddToCart()">
            Přidat do košíku
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import VueNumericInput from "vue-numeric-input";
import { getProductImage } from "@/Helper";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Products",
  mixins: [getProductImage],
  computed: {
    ...mapGetters(["allProducts", "loadingCategories"]),
    filteredProducts() {
      if (!this.search) {
        return this.allProducts;
      }

      return this.allProducts.filter((post) => {
        return post.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  components: {
    VueNumericInput,
  },
  data() {
    return {
      dialog: false,
      currentProduct: {},
      qty: 1,
      search: "",
      primary: window.primary,
      secondary: window.secondary
    };
  },
  methods: {
    ...mapActions(["addToCart"]),
    openDialog(product) {
      this.currentProduct = product;
      this.dialog = true;
    },
    dialogAddToCart() {
      this.addToCart({ product: this.currentProduct, qty: this.qty });
      this.dialogClose();
    },
    dialogClose() {
      this.dialog = false;
      this.qty = 1;
      this.currentProduct = {};
    },
    checkNewProduct(product) {
      return product?.tags && product.tags.includes('NEW');
    }
  },
};
</script>

<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}

.v-card__title {
  word-break: break-word;
}

.vue-numeric-input {
  input {
    outline: none;
    height: 30px;
  }
  .btn {
    background: transparent;
    box-shadow: none;
    border: none;
    &:hover {
      background: #fec800;
    }
  }
}
.search {
  &.v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
  }
  &.v-text-field.v-text-field--solo .v-input__prepend-outer {
    margin-top: 0;
  }
}
</style>
