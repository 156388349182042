<template>
    <v-container class="white--text copyright dark-background">
        <v-row class="justify-space-between d-flex">
            <span>
                Zoologická zahrada hl. m. Prahy, U Trojského zámku 120/3, 171 00 Praha 7
            </span>
            <span>
                <a href="https://zoopraha.cz" class="white--text" target="_blank">Zoo Praha</a>
            </span>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>

<style lang="scss">
    .copyright {
        font-size: 12px;
    }
</style>
