<template>
    <div>
        <products />
    </div>
</template>

<script>
    import Products from '../components/Category/Products'
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'CategoryPage',
        metaInfo () {
            return {
                title: 'Stravenky pro zvířata',
            }
        },
        watch: {
            loadingCategories: function (newValue) {
                if (!newValue) {
                    if (this.$router.currentRoute.name === 'Homepage') {
                        this.changeCategory(process.env.VUE_APP_MAIN_CATEGORY);
                    }
                }
            }
        },
        mounted() {
            if (this.$router.currentRoute.name === 'Homepage') {
                this.changeCategory(process.env.VUE_APP_MAIN_CATEGORY);
            }
        },
        components: {
            Products
        },
        computed: {
            ...mapGetters([
                'allProducts',
                'currentCategory',
                'loadingCategories'
            ])
        },
        methods: {
            ...mapActions([
                'changeCategory'
            ])
        }
    }
</script>
