<template>
    <div>
        <v-container grid-list-lg class="mt-7">
            <v-layout row wrap>
                <v-flex xs12 class="text-center">
                    <h2>Zadejte, pro koho je dárkový poukaz určen.</h2>
                </v-flex>
                <v-flex xs12>
                    <v-skeleton-loader type="image" v-if="loadingCategories"></v-skeleton-loader>
                    <v-img :src="getProductImage(imageData)" v-else>
                        <div class="giftCardText" v-if="$vuetify.breakpoint.smAndUp">
                            <v-textarea
                                    placeholder="Text věnování (nepovinné)"
                                    v-model="optionalText"
                                    flat
                                    solo
                                    auto-grow
                                    rows="1"
                                    background-color="transparent"
                                    maxlength="50"
                                    counter
                                    @keydown.enter="checkEnter"
                            ></v-textarea>
                            <v-text-field
                                    solo
                                    flat
                                    background-color="transparent"
                                    v-model="name"
                                    placeholder="Jméno obdarovaného"
                                    maxlength="25"
                                    counter
                            ></v-text-field>
                        </div>
                    </v-img>
                </v-flex>
                <v-flex xs12 v-if="$vuetify.breakpoint.xsOnly">
                    <div>
                        <v-textarea
                                placeholder="Text věnování (nepovinné)"
                                v-model="optionalText"
                                auto-grow
                                rows="1"
                                background-color="transparent"
                                maxlength="50"
                                counter
                        ></v-textarea>
                        <v-text-field
                                background-color="transparent"
                                v-model="name"
                                placeholder="Jméno obdarovaného"
                                maxlength="25"
                                counter
                        ></v-text-field>
                    </div>
                </v-flex>
                <v-flex xs12 class="text-center">
                    <v-btn x-large color="primary" :disabled="!name" @click="addGiftToCart({product: currentProduct, giftCard: {name: name, text: optionalText}, giftCardId: giftCardId})">Vložit do košíku</v-btn>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import {getProductImage} from '../Helper'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: 'GiftProductPage',
        metaInfo () {
            return {
                title: this.currentProduct ? this.currentProduct.name : '',
            }
        },
        mixins: [getProductImage],
        data() {
            return {
                name: '',
                optionalText: '',
                giftCardId: ''
            }
        },
        computed: {
            ...mapGetters([
                'allProducts',
                'currentProduct',
                'loadingCategories',
                'cartProducts'
            ]),
            imageData() {
                if (this.currentProduct) {
                    return this.currentProduct.giftCardImageUrl;
                }

                return null;
            }
        },
        mounted() {
            let productAlias = this.$router.currentRoute.params;
            if (typeof productAlias.alias !== 'undefined') {
                this.changeProduct();
            }

            let giftCardId = this.$router.currentRoute.query.giftCard;
            if (typeof giftCardId !== 'undefined' && giftCardId.length) {
                this.giftCardId = giftCardId;
                if (this.cartProducts) {
                    const record = this.cartProducts.find(p => p.giftCardId === this.giftCardId);

                    if (record) {
                        this.name = record.giftCard.name;
                        this.optionalText = record.giftCard.text;
                    }
                }
            }
        },
        watch: {
            cartProducts(newValue) {
                if (newValue) {
                    const record = this.cartProducts.find(p => p.giftCardId === this.giftCardId);

                    if (record) {
                        this.name = record.giftCard.name;
                        this.optionalText = record.giftCard.text;
                    }
                }
            }
        },
        methods: {
            ...mapActions([
                'changeProduct',
                'addGiftToCart'
            ]),
            checkEnter(event) {
                let enterCount = 0;
                for (let i = 0; i < this.optionalText.length; i++) {
                    if (this.optionalText[i] === '\n' || this.optionalText[i] === '\r') {
                        enterCount++;
                    }
                }

                if (enterCount > 0) {
                    event.preventDefault();
                }
            }
        }
    }
</script>

<style lang="scss">
    .giftCardText {
        position: absolute;
        bottom: 107px;
        right: 0;
        width: 530px;
        margin: 0 auto;
        left: 65px;
        .v-input {
            position: relative;
        }
        .v-input__slot {
            margin-bottom: 0;
        }
        .v-text-field__details {
            position: absolute;
            bottom: -10px;
            right: 0;
        }
        .v-text-field.v-text-field--solo .v-input__control input {
            text-align: center;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 28px;
            @media #{$pixel-less-ipad-width} {
                font-size: 22px;
            }
            @media #{$pixel-less-ipad} {
                font-size: 19px;
            }
        }
        .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
            text-align: center;
            text-transform: uppercase;
            font-size: 16px;
            @media #{$pixel-less-ipad-width} {
                font-size: 16px;
            }
        }
        @media #{$pixel-less-ipad-width} {
            bottom: 65px;
            width: 417px;
        }
        @media #{$pixel-less-ipad} {
            bottom: 45px;
            width: 350px;
        }
    }
</style>
