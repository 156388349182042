<template>
    <v-container fluid class="pb-0 px-0 secondary" :class="{'isCheckout': isCheckout}">
        <MainHeader :isCheckout="isCheckout" />
        <div class="primary dark-background-height" />
    </v-container>
</template>

<script>
    import MainHeader from "../Header/MainHeader";

    export default {
        name: 'NavBar',
        props: ['isCheckout'],
        components: {
            MainHeader
        }
    }
</script>

<style>
    .dark-background-height {
        height: 20px;
    }
</style>
