<template>
    <div class="product-detail">
        <v-toolbar flat>
            <v-btn small text @click="backButton">
                <v-icon left>mdi-chevron-left</v-icon>
                Zpět na výpis
            </v-btn>
        </v-toolbar>
        <v-skeleton-loader type="article" v-if="loadingCategories || !currentProduct"></v-skeleton-loader>
        <div v-else>
            <h1>{{ currentProduct.name }}</h1>
            <p class="price primary--text font-weight-bold mt-4 mb-6">{{ currentProduct.amountWithVat }} Kč</p>
            <p v-html="currentProduct.description" class="product-description mb-6"></p>
            <v-layout class="mx-0">
                <vue-numeric-input  v-model="qty" :min="1" :max="10" align="center" size="100px" class="ma-0"></vue-numeric-input>
                <v-btn class="ml-3" color="primary" tile depressed @click="addToCart({product: currentProduct, qty: qty})">Přidat do košíku</v-btn>
            </v-layout>
            <v-layout column class="mt-2 mx-0">
                <div class="subtitle-1">Celková cena: <strong>{{qty * currentProduct.amountWithVat}}</strong> Kč</div>
            </v-layout>
        </div>
    </div>
</template>

<script>
    import VueNumericInput from 'vue-numeric-input'
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'ProductDetail',
        computed: {
            ...mapGetters([
                'currentProduct',
                'currentCategory',
                'loadingCategories'
            ])
        },
        components: {
            VueNumericInput
        },
        data () {
            return {
                qty: 1
            }
        },
        methods: {
            ...mapActions([
                'addToCart'
            ]),
            backButton() {
                this.$router.push('/');
            }
        }
    }
</script>

<style lang="scss">
    .product-detail {
        .v-toolbar__content {
            padding-left: 0;
        }
        .product-description {
            span {
                display: block;
                color: #777;
                &:first-child {
                    margin-bottom: 20px;
                    color: #000000;
                }
            }
        }
        .price {
            font-size: 22px;
        }
        .vue-numeric-input {
            input {
                outline: none;
                height: 100%;
            }
            .btn {
                background: transparent;
                box-shadow: none;
                border: none;
                &:hover {
                    background: #ff9400;
                }
            }
        }
    }
</style>
