import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './mutation-types'
import router from '../router'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({added: state.added, user: state.user}),
});

const debug = process.env.NODE_ENV !== 'production';

// initial state
const state = {
    checkingCustomer: false,
    loadingCategories: false,
    added: [],
    all: [],
    categories: [],
    currentCategory: {},
    currentProduct: {},
    user: {},
    orderId: null,
    paymentMethods: [],
    paymentMethodsLoaded: false,
    shippingMethods: [],
    shippingMethodsLoaded: false,
    snackbar: {
        active: false,
        text: '',
        type: ''
    }
};

// getters
const getters = {
    shippingMethodsLoaded: state => state.shippingMethodsLoaded,
    paymentMethodsLoaded: state => state.paymentMethodsLoaded,
    paymentMethods: state => state.paymentMethods,
    shippingMethods: state => state.shippingMethods,
    user: state => state.user,
    orderId: state => state.orderId,
    snackbar: state => state.snackbar,
    checkingCustomer: state => state.checkingCustomer,
    loadingCategories: state => state.loadingCategories,
    currentCategory: state => state.currentCategory,
    currentProduct: state => state.currentProduct,
    allProducts: state => {
        if (state.currentCategory) {
            const category = state.categories.find(c => c.alias === state.currentCategory.alias);
            if (category) {
                return state.all.filter(item => item.productCategoryId === category.id)
            }
        }
        return state.all
    }, // would need action/mutation if data fetched async
    allCategories: state => state.categories,
    cartProducts: state => {
        return state.added.map(({ productId, qty, giftCard, giftCardId }) => {
            const product = state.all.find(p => p.id === productId);

            if (!product) return null;

            return {
                productId: product.id,
                alias: product.alias,
                name: product.name,
                amountWithVat: product.amountWithVat,
                imageUrl: product.imageUrl,
                isGiftCard: product.isGiftCard,
                giftCardImageUrl: product.giftCardImageUrl,
                qty,
                giftCard,
                giftCardId
            }
        })
    }
};

// actions
const actions = {
    handleFormSubmit() {
        return false;
    },
    hideSnackbar({commit}) {
        commit(types.HIDE_SNACKBAR);
    },
    showSuccessSnackbar ({ commit}, message) {
        commit(types.HIDE_SNACKBAR);
        commit(types.SHOW_SNACKBAR, {message});
        setTimeout(function(){ commit(types.HIDE_SNACKBAR) }, 4000);
    },
    setOrderId({commit}, orderId) {
        commit(types.SET_ORDER_ID, {orderId: orderId});
    },
    emptyCart({commit}) {
        commit(types.EMPTY_CART);
    },
    changeQuantity({commit}, product) {
        commit(types.CHANGE_QUANTITY, {product: product});
    },
    retrieveShippingMethods({commit}) {
        window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_CART + '/' + process.env.VUE_APP_API_ESHOP_ID + '/shippingmethod/items').then(response => {
           commit(types.RETRIEVE_SHIPPING_METHODS, {response: response.data});
           commit(types.SHIPPING_METHODS_LOADED);
        }).catch(error => {console.log(error)});
    },
    retrievePaymentMethods({commit}) {
        window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_CART + '/' + process.env.VUE_APP_API_ESHOP_ID + '/paymentmethod/items').then(response => {
            commit(types.RETRIEVE_PAYMENT_METHODS, {response: response.data});
            commit(types.PAYMENT_METHODS_LOADED);
        }).catch(error => {console.log(error)});
    },
    addToCart({ commit, dispatch }, {product: product, qty: qty}) {
        commit(types.ADD_TO_CART, {
            productId: product.id,
            qty: qty
        });
        dispatch('showSuccessSnackbar', 'Produkt ' + product.name + ' byl přidán do košíku.')
    },
    addGiftToCart({ commit, dispatch }, {product: product, giftCard: params, giftCardId: giftCardId}) {
        commit(types.ADD_GIFT_TO_CART, {
            productId: product.id,
            giftCardId: giftCardId,
            giftCard: params
        });
        dispatch('showSuccessSnackbar', 'Produkt ' + product.name + ' byl přidán do košíku.');
        router.push('/cart');
    },
    removeFromCart({ commit }, product) {
        commit(types.REMOVE_FROM_CART, {
            productId: product.productId
        })
    },
    retrieveProducts({commit, dispatch}, response) {
        commit(types.RETRIEVE_CATEGORIES, {response: response.data});
        dispatch('changeCategory');
        window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_PROJECT + process.env.VUE_APP_API_PROJECT_KEY + '/products').then(response => {
            commit(types.RETRIEVE_PRODUCTS, {response: response.data});
            dispatch('changeProduct');
            commit(types.LOADING_CATEGORIES);
        }).catch(error => {this.onError(error)});
    },
    retrieveProductsAndAddToCart({commit, state}, productId) {
        if (!state.all.length) {
            window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_PROJECT + process.env.VUE_APP_API_PROJECT_KEY + '/products').then(response => {
                commit(types.USER_ADD_TO_CART, {response: response.data, product: productId});
                commit(types.CHECKING_USER);
                router.push('/checkout');
            }).catch(error => {this.onError(error)});
        } else {
            commit(types.USER_ADD_TO_CART, {response: state.all, product: productId});
            commit(types.CHECKING_USER);
            router.push('/checkout');
        }
    },
    retrieveCategories({dispatch, commit}) {
        commit(types.LOADING_CATEGORIES);
        window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_PROJECT + process.env.VUE_APP_API_PROJECT_KEY + '/productcategories').then(response => {
            dispatch('retrieveProducts', response);
        }).catch(error => {console.log(error)});
    },
    changeCategory({commit}) {
        commit(types.CHANGE_CATEGORY);
    },
    changeProduct({commit}) {
        commit(types.CHANGE_PRODUCT);
    },
    scanProduct({commit, dispatch}, {product: productId}) {
        commit(types.CHECKING_USER);
        dispatch('retrieveProductsAndAddToCart', productId)
    },
    checkCustomer({commit, dispatch}, {customer: customerId, product: productId}) {
        commit(types.CHECKING_USER);
        window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_CART + '/' + process.env.VUE_APP_API_ESHOP_ID + '/customer/' + customerId).then(response => {
            commit(types.RETRIEVE_USER, {response: response.data});
            if (productId) {
                dispatch('retrieveProductsAndAddToCart', productId)
            } else {
                commit(types.CHECKING_USER);
                router.push('/');
            }
        }).catch(error => { // eslint-disable-line
            commit(types.CHECKING_USER);
            router.push('/');
        });
    },
    getPaymentFeedback({commit}, paymentData) {
        commit(types.CHECKING_USER);
        window.axios.get(process.env.VUE_APP_ROOT_API + '/api/payment/feedback/' + process.env.VUE_APP_API_ESHOP_ID + paymentData).then(response => {
            commit(types.SET_ORDER_ID, {orderId: {orderNumber: response.data.orderNumber, resultText: response.data.resultText}});
            commit(types.CHECKING_USER);
            router.push('/checkout/success');
        }).catch(error => { // eslint-disable-line
            commit(types.CHECKING_USER);
            router.push('/');
        });
    },
    checkCustomerCard({commit}, {coupon: customerCard, email: email}) {
        commit(types.CHECKING_USER);
        window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_CART + '/' + process.env.VUE_APP_API_ESHOP_ID + '/customer?cardNumber=' + customerCard + '&email=' + email).then(response => {
            commit(types.RETRIEVE_USER, {response: response.data});
        }).catch(error => { // eslint-disable-line
            commit(types.CHECKING_USER);
        });
    }
};

// mutations
const mutations = {
    [types.CHECKING_USER] (state) {
        state.checkingCustomer = !state.checkingCustomer;
    },

    [types.USER_ADD_TO_CART] (state, {response, product}) {
        let productId = product;
        state.all = response;
        state.added.push({
            productId,
            qty: 1
        })
    },

    [types.SHOW_SNACKBAR] (state, {message}) {
        state.snackbar.active = true;
        state.snackbar.message = message;
        state.snackbar.type = 'success';
    },

    [types.EMPTY_CART] (state) {
        state.added = [];
    },

    [types.HIDE_SNACKBAR] (state) {
        state.snackbar.active = false;
    },

    [types.SET_ORDER_ID] (state, {orderId}) {
        state.orderId = orderId;
    },

    [types.ADD_TO_CART] (state, { productId, qty }) {
        const record = state.added.find(p => p.productId === productId && !p.giftCardId);

        if (!record) {
            state.added.push({
                productId,
                qty: qty
            })
        } else {
            record.qty = record.qty + qty;
        }
    },

    [types.ADD_GIFT_TO_CART] (state, { productId, giftCard, giftCardId }) {
        const record = state.added.find(p => p.giftCardId === giftCardId);

        if (!record) {
            state.added.push({
                productId,
                giftCardId: makeId(10),
                qty: 1,
                giftCard: giftCard
            })
        } else {
            record.giftCard = giftCard;
        }
    },


    [types.REMOVE_FROM_CART] (state, { productId }) {
        const record = state.added.find(p => p.productId === productId);

        if (record) {
            state.added = state.added.filter(function(item) {
                return item.productId !== productId
            })
        }
    },

    [types.CHANGE_QUANTITY] (state, {product}) {
        const record = state.added.find(p => p.productId === product.productId);

        if (record) {
            record.qty = product.qty;
        }
    },

    [types.RETRIEVE_SHIPPING_METHODS] (state, {response: methods}) {
        state.shippingMethods = methods;
    },

    [types.SHIPPING_METHODS_LOADED] (state) {
        state.shippingMethodsLoaded = true;
    },

    [types.RETRIEVE_PAYMENT_METHODS] (state, {response: methods}) {
        state.paymentMethods = methods;
    },

    [types.PAYMENT_METHODS_LOADED] (state) {
        state.paymentMethodsLoaded = true;
    },

    [types.RETRIEVE_PRODUCTS] (state, {response: products}) {
        state.all = products;
    },

    [types.RETRIEVE_CATEGORIES] (state, {response: categories}) {
        state.categories = categories;
    },

    [types.CHANGE_CATEGORY] (state) {
        let categoryAlias = router.currentRoute.params;
        if (typeof categoryAlias.alias !== 'undefined') {
            state.currentCategory = state.categories.find(c => c.alias === categoryAlias.alias);
        }
    },

    [types.CHANGE_PRODUCT] (state) {
        let productAlias = router.currentRoute.params;
        if (typeof productAlias.alias !== 'undefined') {
            state.currentProduct = state.all.find(c => c.alias === productAlias.alias);
        }
    },

    [types.LOADING_CATEGORIES] (state) {
        state.loadingCategories = !state.loadingCategories;
    },

    [types.RETRIEVE_USER] (state, {response: user}) {
        state.user = user;
    }
};

function makeId(length) {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default new Vuex.Store({
    state,
    strict: debug,
    getters,
    actions,
    mutations,
    plugins: [vuexLocal.plugin]
})
